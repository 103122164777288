@font-face {
  font-family: "PressStart";
  src: local("PressStart"),
    url("./fonts/PressStart2P-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DotGothic";
  src: local("DotGothic"),
    url("./fonts/DotGothic16-Regular.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500&display=swap");

*,
body {
  font-variant-ligatures: none;
  font-family: "PressStart";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 12px;
  line-height: 12px;
}

h1 {
  font-size: 20px;
  line-height: 20px;
}

h2 {
  font-size: 18px;
  list-style: 18px;
}
